//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { OBSERVER } from './../main.js'
import $ from 'jquery'
import { Overlay } from './../classes/Overlay/Overlay.js'
//-----------------------------------------------------------------------------------------------------------------


// Fonction gérant le menu mobile
export function overlayMenu() {
  new Overlay({
    name: 'Menu',
    structure: {
      openingClass: 'openingMenu',
      closingClass: 'closingMenu',
      buttons: {
        close: '.closeOverlay',
        toggle: '.mobileNavBtn',
      }
    },
    event: {
      name: 'click',
      speed: 800,
    }
  })
}

// Fonction gérant l'overlay Brochure
export function overlayBrochure() {
  new Overlay({
    name: 'Brochure',
    structure: {
      openingClass: 'openingBrochure',
      closingClass: 'closingBrochure',
      buttons: {
        open: '.brochureBtn',
        close: '.closeOverlay',
      }
    },
    event: {
      name: 'click',
      speed: 800,
    }
  })
}

