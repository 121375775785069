//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Création du slick de la bannière
export function slickBanner() {
  $('.homeSlider').slick({
    arrows: true,
    dots: true,
    appendDots: '.bannerDots',
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 500,
    autoplaySpeed: 4500,
    fade: true,
    pauseOnHover:false,
    appendArrows: $('.bannerNav'),
    prevArrow: '<div class=\'prevArrow\'><svg class=\'icon\' role=\'img\'><use xlink:href=\'/themes/flexsystem/assets/images/icons/icons.svg#ico-chevron\'></use></svg></div>',
    nextArrow: '<div class=\'nextArrow\'><svg class=\'icon\' role=\'img\'><use xlink:href=\'/themes/flexsystem/assets/images/icons/icons.svg#ico-chevron\'></use></svg></div>',
  })
}


// Création du slick de la section caractéristiques
export function slickCaracteristics() {
  $('.caracteristicsSlides').slick({
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    draggable: true,
    speed: 500,
    prevArrow: '',
  })

  // Syncronisation du slider et de son pager
  $('.caracteristicsSlides').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    var i = nextSlide + 1
    $('.caracteristics_pager ul li span').removeClass('active')
    $('.caracteristics_pager ul li span[data-slide=\''+ i +'\']').addClass('active')
  })
  $('.caracteristics_pager span[data-slide]').click(function(e) {
    e.preventDefault()
    var slideno = $(this).data('slide')
    $('.caracteristicsSlides').slick('slickGoTo', slideno - 1)
    $('.caracteristics_pager span[data-slide]').removeClass('active')
    $(this).addClass('active')
  })
}


// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 1000 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function() {
    $(carousel).slick('unslick')
  }, delai)
}
