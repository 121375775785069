//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { OBSERVER, SCROLLER } from './../main.js'
import $ from 'jquery'
import { isIE, getCookie } from './helper.js'
//-----------------------------------------------------------------------------------------------------------------

// Empêche une transition si la destination est la page actuelle
export function noSamePageDestination() {
  var links = document.querySelectorAll('a[href]')
  var cbk = function(e) {
    // Si la cible du lien est identique à l'url de la page actuelle ( en retirant l'anchor '#' )
    if (e.currentTarget.href === window.location.href.substring(0, window.location.href.indexOf('#')) ) {
      e.preventDefault()
      e.stopPropagation()
      // Scroller jusqu'au haut de la page
      $('html, body').animate({ scrollTop: 0 }, 600, 'easeInOutExpo')
      location.hash = ''
    }
  }
  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', cbk)
  }
}

// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
export function scrollToBlock(object) {
  object.destination === undefined ? object.destination = 0 : object.destination
  object.duration    === undefined ? object.duration = 600  : object.duration
  object.offset      === undefined ? object.offset = 0      : object.offset

  OBSERVER.add('scrollToBlock', 'click', onClick, object.buttons)
  OBSERVER.on('scrollToBlock')

  function onClick() {
    let destination
    destination = (object.destination === 0 ? object.destination : window.scroller.getElementOffset(document.querySelector(object.destination)).top)
    $('html, body').animate({ scrollTop: destination + object.offset }, object.duration, 'easeInOutExpo')
  }
}


// Fonction initialisant les tiroirs
export function drawers() {
  $('.drawers > li .toggle').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.drawers li.open .drawer').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $('.drawers li.open').removeClass('open')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() { $(chosen).parent().addClass('open') }, 0)
      $('.drawer', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }, complete: function() {
        $('html, body').animate({ scrollTop: $(chosen).offset().top - $('.headerMobile').height() - 30 }, 600, 'easeInOutExpo')
      }})
    }
    return false
  })
}


export function initMenu() {
  var offset = 0
  if($( window ).width() <= 1024 ){ offset = $('.headerMobile').height() * -1 }

  scrollToBlock({ buttons: '.menu-innovation', destination: 'section.innovation', offset: offset })
  scrollToBlock({ buttons: '.menu-benefits', destination: 'section.benefits', offset: offset })
  scrollToBlock({ buttons: '.menu-caracteristics', destination: 'section.caracteristics', offset: offset })
  scrollToBlock({ buttons: '.menu-innovative', destination: 'section.multifaces', offset: offset })
  scrollToBlock({ buttons: '.menu-contact', destination: 'section.contact', offset: offset })

  if(window.location.hash == '#innovation') {
    var destination = window.scroller.getElementOffset(document.querySelector('section.innovation')).top
    $('html, body').animate({ scrollTop: destination + offset }, 600, 'easeInOutExpo')
  }
  if(window.location.hash == '#benefits') {
    var destination = window.scroller.getElementOffset(document.querySelector('section.benefits')).top
    $('html, body').animate({ scrollTop: destination + offset }, 600, 'easeInOutExpo')
  }
  if(window.location.hash == '#caracteristics') {
    var destination = window.scroller.getElementOffset(document.querySelector('section.caracteristics')).top
    $('html, body').animate({ scrollTop: destination + offset }, 600, 'easeInOutExpo')
  }
  if(window.location.hash == '#contact') {
    var destination = window.scroller.getElementOffset(document.querySelector('section.contact')).top
    $('html, body').animate({ scrollTop: destination + offset }, 600, 'easeInOutExpo')
  }
}

// Initialiser la hauteur de la bannière de l'accueil
export function initHomeBannerHeight() {
  adjustBannerHeight()
  $(window).resize( function() {
    adjustBannerHeight()
  })
}
function adjustBannerHeight(){
  var windowHght = $(window).height()
  $('.homeBanner').css('height', windowHght + 'px')
}

export function init360view() {
  var numberOfSlides = $('.rotation360 .images > *').length

  // Initialiser le slider
  $( '.rotationSlider' ).slider({
    min: 1,
    max: numberOfSlides,
    value: numberOfSlides/2,
    slide: function( event, ui ) {
      $('.rotation360 .images > *').css('opacity',0)
      $('.rotation360 .images > :nth-child('+ui.value+')').css('opacity',1)
    }
  })
  // Initialiser la slide du milieu
  $('.rotation360 .images > :nth-child('+numberOfSlides/2+')').css('opacity',1)
}
