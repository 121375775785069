//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------


// LES FONCTIONS
// Fonction qui gère le label des input
export function fixInput() {
  // Si le focus est sur le champ, on enlève la classe vide pour que le label se déplace
  $('input, textarea').focusin(function() {
    $(this).removeClass('notEmpty')
  })

  // Si le champ est vide, on replace le label à sa position d'origine au focusout
  $('input, textarea').focusout(function() {
    ($(this).val() != '' ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty'))
  })
}


// Permet de supprimer le contenu d'un input
export function clearInput() {
  let i
  let btn = document.getElementsByClassName('clearInput')
  let btnLength = btn.length

  for (i = 0; i < btnLength; i++) {
    btn[i].addEventListener('click', (e) => {
      let input = e.currentTarget.parentElement.getElementsByTagName('input')[0]
      input.value = ''
      input.focus()
      input.parentElement.classList.remove('notEmpty')
      input.classList.remove('valid')
    })
  }
}


// Ajoute une classe en considérant si le input est plein ou vide
export function checkInput() {
  let i
  let input = document.querySelectorAll('input')
  let inputLength = input.length

  for (i = 0; i < inputLength; i++) {
    input[i].addEventListener('input', function(e) {
      if (e.target.value.length >= 1) {
        e.target.parentElement.classList.add('notEmpty')
      } else {
        e.target.parentElement.classList.remove('notEmpty')
      }
    })
  }
}


// Fonction permettant de modifier la hauteur d'un textarea par rapport à son contenu
export function textareaHeight() {
  $('body').on('change keydown keyup keydown paste cut', 'textarea', function() {
    $(this).height(0).height(this.scrollHeight)
  }).find('textarea').change()
}


// Transforme les selects
export function magicSelect() {
  $('.chosen-select').chosen({ disable_search: true })
}



// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf, docx, doc or rtf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}



// LES RÈGLES __________
// Règle du email
export function validationEmail() {
  jQuery.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}



// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('(000) 000-0000')
}

// Format du code postal
export function formatCodePostal(element) {
  $(element).mask('ABA BAB', {'translation': {
    A: {pattern: /[A-Za-z]/},
    B: {pattern: /[0-9]/}
  }})
}

// Format de la date
export function formatDate(element) {
  $(element).mask('00/00/00')
}



// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (element.attr('type') == 'radio') {
        error.insertBefore(element.closest('.radiobox-field').find('.errorblock'))
      } else if (element.attr('type') == 'checkbox') {
        error.insertBefore(element.closest('.checkbox-field').find('.errorblock'))
      } else if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    },
    submitHandler: function() {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  $(submit).click(function() {
    $(form).submit()
  })
}


// Validation du formulaire de demande de brochure
export function formBrochure() {
  validationEmail()
  var m = getMessages()
  var rules = {
    company: { required: true },
    courriel: { required: true, courriel: true },
  }
  var messages = {
    company: { required: m.required },
    courriel: { required: m.required, courriel: m.email },
  }

  formValidation('#formBrochure', '#submitBrochure', rules, messages)
}

// Validation du formulaire de contact
export function formContact() {
  validationEmail()
  var m = getMessages()
  var rules = {
    nom: { required: true },
    courriel: { required: true, courriel: true },
    message: { required: true },
  }
  var messages = {
    nom: { required: m.required },
    courriel: { required: m.required, courriel: m.email },
    message: { required: m.required },
  }

  formValidation('#formContact', '#submitContact', rules, messages)
}