//-----------------------------\\
//--- Transition avec barba ---\\
//-----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// C'est ici que les transition entre les page sont gérées, on y retrouve les différentes views spécifiques à
// chaque page. Tout commence avec la fonction doOnce.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'
import { setView } from './views.js'
//-----------------------------------------------------------------------------------------------------------------

// Récupérer l'élément cliqué
window.transitionElement = ''


// Cette fonction Gère la transition entre les page
export function initPageTransition() {
  Barba.Pjax.cacheEnabled = false
  Barba.Pjax.Dom.wrapperId = 'transition-wrapper'
  Barba.Pjax.Dom.containerClass = 'transition-container'
  Barba.Pjax.ignoreClassLink = 'no-transition'
  Barba.Dispatcher.on('initStateChange', function (currentStatus) {
    if( typeof gtag === 'function' ) gtag('config', 'UA-150959240-15', { 'page_path': location.pathname }) // Google tag
  })
  setTransition()
  setView()
  Barba.Pjax.start()
}


// Cette fonctions exécute la transition entre les pages
function setTransition() {
  var FadeTransition = Barba.BaseTransition.extend({
    start: function() {
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this))
    },
    fadeOut: function() {
      return $('html').addClass('transition').delay(500).promise()
    },
    fadeIn: function() {
      if (!window.location.hash) $(document).scrollTop(0)
      $('html').addClass('end').removeClass('transition').delay(700).queue(function(next){
        $(this).removeClass('end')
        next()
      })
      this.done()
    }
  })

  Barba.Pjax.getTransition = function() {
    return FadeTransition
  }
}
