//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { OBSERVER, SCROLLER, PARALLAX, SCROLLFIRE } from './main.js'
import svg4everybody from 'svg4everybody/dist/svg4everybody.legacy.js'
import { removeHoverOnMobile } from './functions/helper.js'
import { fixInput, formContact, formBrochure, textareaHeight } from './functions/forms.js'
import { overlayMenu, overlayBrochure } from './functions/overlay.js'
import { noSamePageDestination, initHomeBannerHeight, initMenu, drawers, init360view } from './functions/functions.js'
import { slickBanner, slickCaracteristics } from './functions/slicks.js'
//-----------------------------------------------------------------------------------------------------------------


// Initialisation sur toutes les pages
export class View {
  static init() {
    SCROLLER.init()
    PARALLAX.init({ customScroll: true })
    SCROLLFIRE.init({ customScroll: true, mobile: true })
    removeHoverOnMobile()
    overlayMenu()
    svg4everybody()
    noSamePageDestination()
    initMenu()
    overlayBrochure()
    formContact()
    formBrochure()
  }

  static leave() {
    setTimeout(() => {
      SCROLLER.destroy()
      PARALLAX.destroy()
      SCROLLFIRE.destroy()
    }, 400)
  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    this.init()
    initHomeBannerHeight()
    slickBanner()
    init360view()
    slickCaracteristics()
    fixInput()
    textareaHeight()
  }

  static leaveHome() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class FAQ extends View {
  static initFAQ() {
    this.init()
    fixInput()
    drawers()
  }

  static leaveFAQ() {
    this.leave()
  }
}

